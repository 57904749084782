'use client';

import { TabCardAccordion, Tabs } from '@/components/Tabs';
import { TabCardAccordionType } from '@/components/Tabs/TabCard';
import { useState } from 'react';
import { WrenchIcon } from '@heroicons/react/24/outline';

export type FeatureTabsProps = {
  title: string;
  tabItems: {
    id: number;
    text: string;
    icon: typeof WrenchIcon | (({ className }: { className: string }) => JSX.Element);
  }[];
  tabContent: TabCardAccordionType[];
};

export default function FeatureTabs({ title, tabItems, tabContent }: FeatureTabsProps) {
  const [tabIdx, setTabIdx] = useState<number>(0);
  const selectedTab = tabContent[tabIdx];

  return (
    <section className="py-section text-body bg-gray-1 text-center text-white">
      <div className="max-w-section flex w-full flex-col items-center gap-14">
        <h2 className="text-h3 max-w-[48rem]">{title}</h2>
        <div className="flex flex-col gap-6 md:gap-10">
          <div className="flex flex-col gap-10">
            <Tabs tabItems={tabItems} tabIdx={tabIdx} setTabIdx={setTabIdx} />
          </div>
          <div className="flex flex-col gap-14 px-4 md:px-10 lg:px-0">
            <TabCardAccordion {...selectedTab} />
          </div>
        </div>
      </div>
    </section>
  );
}
