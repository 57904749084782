import Image, { StaticImageData } from 'next/image';
import { AccordionSimple } from '@/components/Accordion';
import { Fragment, useState } from 'react';
import Link from 'next/link';

export type TabContentType = {
  id: number;
  image: StaticImageData;
  title: string;
  content: string;
  link: string;
  linkText: string;
};

type TabCardProps = {
  item: TabContentType;
};

export const TabCard = ({ item }: TabCardProps) => {
  return (
    <div className="flex animate-fade-in flex-col gap-8 rounded-2xl bg-[#13171B] p-6 opacity-0 transition-opacity duration-700 md:p-8 lg:h-[31rem] lg:flex-row lg:gap-14 lg:p-14">
      <div className="flex flex-1 flex-col items-start justify-center gap-4">
        <h5 className="text-h4">{item.title}</h5>
        <p className="text-body text-left text-gray-4">{item.content}</p>
      </div>
      <div className="flex flex-1 items-center justify-center">
        <Image alt={item.title} className="self-center rounded-2xl" src={item.image} />
      </div>
    </div>
  );
};

export type TabCardAccordionType = {
  id: number;
  image: StaticImageData;
  title: string;
  accordionData: {
    title: string;
    body: string;
    link: string;
    linkText: string;
  }[];
};

export type TabCardAccordionProps = {
  title?: string;
  image: StaticImageData;
  imageAlt?: string;
  imagePosition?: 'left' | 'right';
  accordionData: {
    title: string;
    body: string;
    link?: string;
    linkText?: string;
  }[];
};

export const TabCardAccordion = ({ title, image, imageAlt, imagePosition, accordionData }: TabCardAccordionProps) => {
  const [openIndex, setOpenIndex] = useState<number>(0);

  const handleNoPropClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return (
    <div className="flex animate-fade-in flex-col gap-6 rounded-3xl bg-[#13171B] px-6 py-6 opacity-0 transition-opacity duration-700 md:px-8 md:py-10 lg:gap-10 lg:gap-14 lg:px-14">
      {title && <h4 className="text-h6">{title}</h4>}
      <div
        className={`flex flex-col-reverse gap-6 lg:flex-row lg:gap-10 ${imagePosition === 'left' ? 'lg:flex-row-reverse' : ''}`}
      >
        <div className="flex flex-1 flex-col items-start justify-center gap-4">
          {accordionData.map((accordionSection, index) => (
            <Fragment key={index}>
              <AccordionSimple
                title={accordionSection.title}
                index={index}
                isOpen={openIndex === index}
                setOpenIndex={setOpenIndex}
              >
                <div className="mb-1 flex flex-col items-start gap-4">
                  <p className="text-body max-w-[90%] text-left text-gray-4">{accordionSection.body}</p>
                  {accordionSection.link && (
                    <Link
                      onClick={handleNoPropClick}
                      className="underline-text-transition-blue text-left"
                      href={accordionSection.link}
                      target="_blank"
                    >
                      {accordionSection.linkText}
                    </Link>
                  )}
                </div>
              </AccordionSimple>
              <div className="my-3 h-[1px] w-full bg-dark-background"></div>
            </Fragment>
          ))}
        </div>
        <div className="flex flex-1 items-center justify-center">
          <Image alt={imageAlt || ''} className="max-h-96 self-center rounded-2xl object-cover" src={image} />
        </div>
      </div>
    </div>
  );
};
